import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { toast } from 'react-toastify'
import { CasperClient, CLPublicKey, CLValueBuilder, decodeBase16, DeployUtil, RuntimeArgs } from 'casper-js-sdk'
import { useCurrentNetwork } from 'hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import Modal from 'components/Modal'
import Button from 'components/Button'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import Loader from 'components/Loader'
import { CEP78Client } from 'casper-cep78-js-client'

const StyledModal = styled(Modal)`
  .modal-body {
    padding: 0 2.5rem 3.75rem;
  }
`

const InputWrapper = styled.div`
  margin-bottom: 2rem;
`

const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  color: #191820;
  margin-bottom: 2rem;
  margin-top: 1rem;
  text-align: center;
`

const InputField = styled.fieldset`
  position: relative;
  margin-bottom: 1rem;

  label {
    position: absolute;
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    right: 1.25rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: 1px solid #e5e5e5;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    border-radius: 24px;
    padding: 11px 21px;

    &:focus {
      outline: none !important;
      border-color: #b9b8bb;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface IOfferNFTModalProps {
  nft: any
  show: boolean
  onHide: () => void
}

function TransferNFTModal(props: IOfferNFTModalProps): JSX.Element {
  const { nft, show, onHide } = props

  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const connectorId = useConnectorId()
  const provider = useWalletProvider()

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')

  const [address, setAddress] = useState<string>('')
  const [isActive, setActive] = useState<boolean>(false)

  const [registered, setRegistered] = useState<boolean>(false)
  const [checkingRegistration, setCheckingRegistration] = useState<boolean>(false)
  const [registering, setRegistering] = useState<boolean>(false)

  const checkRegistration = async () => {
    try {
      setCheckingRegistration(true)

      if (account && currentNetwork && address) {
        const _cep78Client = await CEP78Client.createInstance(
          currentNetwork.contract.Box,
          currentNetwork.rpcURL,
          currentNetwork.key ?? 'casper-test',
        )

        const _ownerHash = CLPublicKey.fromHex(address).toAccountHashStr()
        const ownerHash = _ownerHash.split('-')[2]
        const _result = await _cep78Client.checkRegisterOwner(ownerHash)
        setRegistered(_result)
        setCheckingRegistration(false)
      }
    } catch (error) {
      console.error(error)
      setCheckingRegistration(false)
    }
  }

  const onRegisterOwner = async () => {
    try {
      setRegistering(true)
      const gasFee = 1000000000

      if (account && currentNetwork) {
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(currentNetwork?.contract.Box)

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'register_owner',
            RuntimeArgs.fromMap({
              token_owner: CLValueBuilder.key(CLPublicKey.fromHex(address)),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined
          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _registerDeploy = DeployUtil.deployFromJson(signature)
            deployObject = _registerDeploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Registered ${address.substring(0, 6)}...${address.substring(address.length - 4)}.`,
              })

              setRegistering(false)
            })
            .catch((error: any) => {
              console.error(error)
              setRegistering(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onTransferNFT = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      const gasFee = 20000000000

      if (account && currentNetwork) {
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Box)

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'transfer',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.u64(nft.token_id),
              source_key: CLValueBuilder.key(CLPublicKey.fromHex(account)),
              target_key: CLValueBuilder.key(CLPublicKey.fromHex(address)),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined
          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _transferDeploy = DeployUtil.deployFromJson(signature)
            deployObject = _transferDeploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Transfer #${nft.token_id} to ${address.substring(0, 6)}...${address.substring(
                  address.length - 4,
                )} .`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
              onHide()
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  useEffect(() => {
    address.length === 68 || address.length === 66 ? setActive(true) : setActive(false)
    checkRegistration()
  }, [address])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <>
      <StyledModal show={show} title="Transfer Digital Collectibles" onHide={onHide}>
        <ModalSubTitle>Transfer #{nft.token_id}</ModalSubTitle>
        <InputWrapper>
          <InputField>
            <input value={address} onChange={e => setAddress(e.target.value)} placeholder="Receiver" />
          </InputField>
        </InputWrapper>
        <ButtonWrapper>
          {checkingRegistration && address ? (
            <Button type="primary">
              <Loader /> Checking receiver
            </Button>
          ) : (
            <>
              {registered ? (
                <Button type="primary" loading={attemptingTxn} handleClick={onTransferNFT} disabled={!isActive}>
                  Transfer
                </Button>
              ) : (
                <Button type="primary" handleClick={onRegisterOwner}>
                  {registering ? (
                    <>
                      <Loader /> Registering
                    </>
                  ) : (
                    'Register receiver'
                  )}
                </Button>
              )}
            </>
          )}
        </ButtonWrapper>
      </StyledModal>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title="Transfer NFT"
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </>
  )
}

export default TransferNFTModal
