import Auctions from './Auctions'
import SectionHero from './SectionHero'
// import Collection from './Collection'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

function HomePage(): JSX.Element {
  return (
    <>
      <SectionHero />
      <Auctions />
      {/* <Collection /> */}
    </>
  )
}

export default HomePage
