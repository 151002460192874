import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useCurrentNetwork } from 'hooks'
import { ipfsURLConvert } from 'utils'
import PriceTag from 'components/PriceTag'
import Button from 'components/Button'
import { AuctionBoxWrapper, BoxTitle, AuctionImage, InfoWrapper, InfoTitle, ButtonWrapper } from './Style'
import BigNumber from 'bignumber.js'
import LootBox from 'assets/images/loot-box.png'

interface IAuctionBoxProps {
  nft: any
  connectWalletCallback: () => void
  showBtn?: boolean
}

function AuctionBox(props: IAuctionBoxProps): JSX.Element {
  const { nft } = props
  const currentNetwork = useCurrentNetwork()
  const history = useHistory()

  const [price, setPrice] = useState<any>()
  const [lastPrice, setLastPrice] = useState<any>()
  const [bidPrice, setBidPrice] = useState<any>()

  useEffect(() => {
    if (nft?.minimumOffer) {
      const _price = new BigNumber(nft?.minimumOffer).div(1e9).toString()
      setPrice(_price)
    }
    if (nft?.biddingPrice) {
      const _biddingPrice = new BigNumber(nft?.biddingPrice).div(1e9).toString()
      setBidPrice(_biddingPrice)
    }
    if (nft?.lastPrice) {
      const _lastPrice = new BigNumber(nft?.lastPrice).div(1e9).toString()
      setLastPrice(_lastPrice)
    }
  }, [currentNetwork, nft])

  const pushHistory = () => {
    history.push({ pathname: `/details/${nft.nftContract}/${nft.tokenId}` })
  }

  return (
    <>
      <AuctionBoxWrapper onClick={pushHistory}>
        <div className="d-flex justify-content-between align-items-center">
          <BoxTitle>#{nft.tokenId}</BoxTitle>
        </div>
        <AuctionImage>
          <img src={LootBox} alt="" />
        </AuctionImage>
        {nft.isActive === true || price === undefined ? (
          <InfoWrapper>
            <InfoTitle>Price: </InfoTitle>
            <PriceTag price={price} symbol="CSPR" />
          </InfoWrapper>
        ) : (
          <InfoWrapper>
            <InfoTitle>Price:</InfoTitle>
            <PriceTag price={'--'} />
          </InfoWrapper>
        )}

        {lastPrice ? (
          <InfoWrapper>
            <InfoTitle>Last Sale: </InfoTitle>
            <PriceTag price={lastPrice} symbol="CSPR" />
          </InfoWrapper>
        ) : (
          <InfoWrapper>
            <InfoTitle>&nbsp;</InfoTitle>
          </InfoWrapper>
        )}
        {bidPrice && (
          <InfoWrapper>
            <InfoTitle>Bidding Price: </InfoTitle>
            <PriceTag price={bidPrice} symbol="CSPR" />
          </InfoWrapper>
        )}
        <ButtonWrapper onClick={e => e.stopPropagation()}>
          <Button type="primary" handleClick={pushHistory}>
            View Detail
          </Button>
        </ButtonWrapper>
      </AuctionBoxWrapper>
    </>
  )
}

export default AuctionBox
