import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { useCurrentNetwork, useMetadata, useMarketplace, useMarketInfo, useCSPRPrice } from 'hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import SectionTitle from 'components/SectionTitle'
import AuctionBox from 'components/AuctionBox'
import Loader from 'components/Loader'
import ConnectModal from 'components/ConnectButton/ConnectModal'
import { toCSPR, toInternationalCurrencySystemNature } from 'utils'
import { useHistory } from 'react-router-dom'
import Layout from 'components/Layout'

const SectionWrapper = styled.div`
  padding: 5rem 0;

  @media (min-width: 992px) {
    padding: 2.5rem 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 5rem;
`

const NFTWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 2rem;
`

const BoxWrap = styled.div`
  margin-bottom: 0.5rem;
  min-height: 475px;

  @media (min-width: 576px) {
    width: 48%;
  }

  @media (min-width: 768px) {
    width: 31%;
  }

  @media (min-width: 992px) {
    width: 23%;
  }
`

const MarketInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 40px;
  margin-top: 20px;
`

const MarketInfoItem = styled.div`
  display: flex;
  flex-direction: column;

  span:first-child {
    font-size: 22px;
    font-weight: 500;
  }

  span:last-child {
    opacity: 0.7;
    font-weight: 300;
  }
`

// fn compare
function compareTokens(a, b) {
  const arrA = a.tokenId.split('-')
  const arrB = b.tokenId.split('-')

  for (let i = 0; i < arrA.length; i++) {
    if (!isNaN(arrA[i])) {
      arrA[i] = parseInt(arrA[i])
      arrB[i] = parseInt(arrB[i])
    }
  }

  for (let i = 0; i < arrA.length; i++) {
    if (arrA[i] < arrB[i]) {
      return -1
    } else if (arrA[i] > arrB[i]) {
      return 1
    }
  }

  return a.tokenId.localeCompare(b.tokenId)
}

export type Info = {
  totalVolume: string
  floorPrice: string
  bestOffer: string
  listedPercentage: string
  bestBidTokenId: string | undefined | null
  floorBidTokenId: string | undefined | null
}

const toPrice = (number: string | undefined | null, price: number): string => {
  if (number) {
    return Number(Number(toCSPR(number)) * price).toFixed(2)
  } else {
    return '0'
  }
}

function Gen1Boxes(): JSX.Element {
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const metadataCallback = useMetadata()
  const sellsCallback = useMarketplace(false)
  const marketInfoCallback = useMarketInfo()
  const history = useHistory()
  const csprPriceCallback = useCSPRPrice()

  const [showConnectModal, setShowConnectModal] = useState(false)
  const [tokenMarket, setTokenMarket] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [marketInfo, setMarketInfo] = useState<Info>({
    totalVolume: '0',
    floorPrice: '0',
    bestOffer: '0',
    listedPercentage: '0',
    bestBidTokenId: '',
    floorBidTokenId: '',
  })
  const [csprPrice, setCSPRPrice] = useState<number>(0)

  const pushHistory = (tokenId: string | null | undefined) => {
    if (tokenId) {
      history.push({ pathname: `/details/${currentNetwork?.contract.NFT}/${tokenId}` })
    }
  }

  const fetchNFTs = async () => {
    try {
      setLoading(true)

      if (currentNetwork) {
        // @ts-ignore
        const _nfts = await sellsCallback()
        setTokenMarket(_nfts)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const fetMarketInfo = async () => {
    const _marketInfo = await marketInfoCallback()
    setMarketInfo(_marketInfo)
  }

  const fetchPrice = async () => {
    const _price = await csprPriceCallback()
    setCSPRPrice(_price)
  }

  useEffect(() => {
    fetchPrice()
    fetMarketInfo()
    const interval = setInterval(() => {
      fetMarketInfo()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    fetchNFTs()
  }, [account, chainId, currentNetwork])

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <SectionTitle color="dark">Gen 1 CasperPunks</SectionTitle>
          <MarketInfoWrapper>
            <MarketInfoItem>
              <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.totalVolume), 1)}</span>
              <span>~${toPrice(marketInfo?.totalVolume, csprPrice)}</span>
              <span>Total Volume</span>
            </MarketInfoItem>
            <MarketInfoItem style={{ cursor: 'pointer' }} onClick={() => pushHistory(marketInfo.floorBidTokenId)}>
              <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.floorPrice), 1)}</span>
              <span>~${toPrice(marketInfo?.floorPrice, csprPrice)}</span>
              <span>Floor Price</span>
            </MarketInfoItem>
            <MarketInfoItem style={{ cursor: 'pointer' }} onClick={() => pushHistory(marketInfo.bestBidTokenId)}>
              <span>{toInternationalCurrencySystemNature(toCSPR(marketInfo?.bestOffer), 1)}</span>
              <span>~${toPrice(marketInfo?.bestOffer, csprPrice)}</span>
              <span>Best Offer</span>
            </MarketInfoItem>
            <MarketInfoItem>
              <span>{marketInfo?.listedPercentage ? marketInfo?.listedPercentage : 0}%</span>
              <span>Listed</span>
            </MarketInfoItem>
          </MarketInfoWrapper>
          {loading ? (
            <ContentWrapper>
              <Loader size="40px" />
            </ContentWrapper>
          ) : (
            <>
              {tokenMarket?.length > 0 ? (
                <NFTWrapper>
                  {tokenMarket.map((nft, index) => (
                    <BoxWrap key={index}>
                      <AuctionBox
                        nft={nft}
                        showBtn={true}
                        connectWalletCallback={() => {
                          setShowConnectModal(true)
                        }}
                      />
                    </BoxWrap>
                  ))}
                </NFTWrapper>
              ) : (
                <ContentWrapper>No Digital Collectibles found.</ContentWrapper>
              )}
            </>
          )}
        </Container>
        <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
      </SectionWrapper>
    </Layout>
  )
}

export default Gen1Boxes
