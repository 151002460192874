import styled from 'styled-components/macro'

export const AuctionBoxWrapper = styled.div`
  display: grid;
  align-content: start;
  gap: 0.5rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 15px;
  padding: 0.5rem 1.5rem 1.5rem;
  width: fit-content;
  height: 100%;
  margin: auto;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-0.75rem);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    img:not(#creator) {
      transform: scale(1.1);
      transition: all 0.5s ease;
    }
  }
`

export const BoxTitle = styled.span`
  position: relative;
  font-weight: 300;
  font-size: 22px;
  line-height: 40px;
  height: 40px;
  z-index: 1;
`

export const AuctionImage = styled.div`
  position: relative;
  overflow: hidden;

  svg {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`

export const InfoTitle = styled.p`
  font-weight: 300;
  font-size: 17px;
  line-height: 40px;
  margin-right: 5px;
  margin-bottom: 0;
`

export const ButtonWrapper = styled.div`
  display: flex;

  button {
    width: 100%;
  }
`
