import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import {
  ButtonWrapper,
  HeaderMain,
  HeaderWrapper,
  TitleWapper,
  MenuToggle,
  NavBarWrap,
  NavLink,
  NavLink2,
  StyledWalletIcon,
  HeaderMobile,
  MobileLogo,
  NavLinkA,
} from './Styled'
import LogoLight from 'assets/images/logo-light.svg'
import ConnectButton from 'components/ConnectButton'
import { FiMenu } from 'react-icons/fi'

function HeaderSecond(): JSX.Element {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true)
  }

  return (
    <HeaderMain>
      <HeaderWrapper>
        <TitleWapper>
          <span>Marketplace</span>
        </TitleWapper>
        <NavBarWrap className="d-none d-lg-flex flex-grow-1">
          <ul className="main-menu list-unstyled">
            <li>
              <NavLink to="/gen1boxes">Gen 1 CasperPunks</NavLink>
            </li>
            <li>
              <NavLinkA href="https://gen0cpdc.casperpunks.io/gen0punks" target="_blank">
                Gen 0 CasperPunks
              </NavLinkA>
            </li>
            <li>
              <NavLink to="/portfolio">Portfolio</NavLink>
            </li>
          </ul>
          <ButtonWrapper>
            <ConnectButton />
          </ButtonWrapper>
          <StyledWalletIcon size={24} />
        </NavBarWrap>
      </HeaderWrapper>
      <HeaderMobile className="container">
        <MenuToggle onClick={handleMobileMenuOpen}>
          <FiMenu size={24} />
        </MenuToggle>
        <MobileLogo src={LogoLight} />
        <ConnectButton />
        <Offcanvas show={mobileMenuOpen} onHide={() => setMobileMenuOpen(false)}>
          <Offcanvas.Header closeButton />
          <Offcanvas.Body>
            <ul className="main-menu list-unstyled">
              <li>
                <NavLink2 href="/gen1boxes">Gen 1 CasperPunks</NavLink2>
              </li>
              <li>
                <NavLink2 href="/portfolio">Portfolio</NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/whitelist" target="_blank">
                  Whitelist
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/#about" target="_blank">
                  About
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/#minting" target="_blank">
                  Minting
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/#roadmap" target="_blank">
                  Roadmap
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="/">Marketplace</NavLink2>
              </li>
              <li>
                <NavLink2 href="https://casperpunks.io/faq" target="_blank">
                  FAQ
                </NavLink2>
              </li>
              <li>
                <NavLink2 href="/connectwallet">Create Wallet</NavLink2>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </HeaderMobile>
    </HeaderMain>
  )
}

export default HeaderSecond
