import { useCallback, useEffect, useState } from 'react'
import { Container, Tab } from 'react-bootstrap'
import StyledTab from 'components/Tab'
import DetailsTab from './DetailsTab'
import BidsTab from './BidsTab'
import HistoryTab from './HistoryTab'
import { useParams } from 'react-router-dom'
import { CEP78Client } from 'casper-cep78-js-client'
import { useBid, useCurrentNetwork, useMarketplace, useNFTMetadata, useSell } from 'hooks'
import { useAccount, useConnectorId, useWalletProvider } from 'state/wallet/hooks'
import {
  SectionWrapper,
  ContentWrapper,
  ItemImage,
  DefaultImage,
  InfoWrapper,
  ItemName,
  Description,
  BidWrapper,
  ButtonWrapper,
} from './Style'
import {
  CasperClient,
  CLByteArray,
  CLPublicKey,
  CLValueBuilder,
  decodeBase16,
  DeployUtil,
  RuntimeArgs,
} from 'casper-js-sdk'
import { createRecipientAddress } from 'casper-js-client-helper/dist/helpers/lib'
import SellNFTModal from 'components/SellNFTModal'
import axios from 'axios'
import PriceTag from 'components/PriceTag'
import Button from 'components/Button'
import { toast } from 'react-toastify'
import { useTransactionAdder } from 'state/transactions/hooks'
import BidNFTModal from 'components/BidNFTModal'
import BigNumber from 'bignumber.js'
import { CiWallet } from 'react-icons/ci'
import ConnectModal from 'components/ConnectButton/ConnectModal'
import TransactionConfirmationModal from 'components/TransactionConfirmationModal'
import IncreaseBidNFTModal from 'components/IncreaseBidNFTModal'
import ChangePriceNFTModal from 'components/ChangePriceNFTModal'
import Layout from 'components/Layout'
import LootBox from 'assets/images/loot-box.png'

function ItemDetails(): JSX.Element {
  const { contractHash, id } = useParams<{ contractHash: string; id: string }>()
  const account = useAccount()
  const currentNetwork = useCurrentNetwork()
  const marketplaceCallback = useMarketplace()
  const nftMetadataCallback = useNFTMetadata(id)
  const sellCallback = useSell(id)
  const connectorId = useConnectorId()
  const provider = useWalletProvider()
  const bidCallback = useBid(id, contractHash)

  const addTransaction = useTransactionAdder()
  const [showConfirm, setShowConfirm] = useState(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [bidHistory, setBidHistory] = useState<any>([])

  const [imgUrl, setImgUrl] = useState<string>('')
  const [title, setTitle] = useState<string>('')

  const [showSellModal, setShowSellModal] = useState(false)
  const [showBidModal, setShowBidModal] = useState(false)
  const [showIncreaseBidModal, setShowIncreaseBidModal] = useState(false)
  const [showChangePriceModal, setShowChangePriceModal] = useState(false)
  const [isBidding, setBidding] = useState<boolean>(false)
  const [isSelling, setSelling] = useState<boolean>(false)
  const [currentBiddingPrice, setCurrentBiddingPrice] = useState<string | undefined>()
  const [totalBid, setTotalBid] = useState<number>(0)

  const [tokenSymbol] = useState('PNK')
  const [tokenMeta, setTokenMeta] = useState<any>()
  const [tokenOwner, setTokenOwner] = useState<any>()

  const [accountHash, setAccountHash] = useState<string | undefined>()
  const [showConnectModal, setShowConnectModal] = useState(false)

  // const [fee] = useState(8 / 100)
  const [price, setPrice] = useState<string>('')
  const [nft, setNFT] = useState<any>()

  const nftForSell = {
    token_id: id,
    nft_contract: {
      Hash: contractHash,
    },
  }

  const fetchNFTInfo = async () => {
    if (account && currentNetwork && id) {
      const nftContract = await CEP78Client.createInstance(contractHash, currentNetwork?.rpcURL, currentNetwork.key, [])

      try {
        const _meta = await nftContract.getTokenMetadata(id)
        const array = Array.from(_meta, ([key, value]) => ({ key, value }))
        const _account = CLPublicKey.fromHex(account).toAccountHashStr()
        const _splitAccountHex = _account.split('-')
        setAccountHash(_splitAccountHex[2])

        const _owner = await nftContract.getOwnerOf(id)
        const _splitOwner = _owner.includes('account-hash') ? _owner.split('-') : _owner
        const _ownerHash = typeof _splitOwner === 'string' ? _owner : _splitOwner[2]

        const _sell = await sellCallback()
        if (_sell?.length > 0) {
          setSelling(_sell[0].isActive)
        } else {
          setSelling(false)
        }
        if (_sell?.length > 0 && _sell[0].isActive === true) {
          setTokenOwner(_sell[0]?.offeror)
        } else {
          setTokenOwner(_ownerHash)
        }

        setTokenMeta(array)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    fetchNFTInfo()
  }, [account, currentNetwork, id])

  const fetchMarketplaceInfo = async () => {
    let data = await marketplaceCallback()
    data = data.filter(e => e.tokenId === id)
    if (data?.length > 0) {
      setNFT(data[0])
      const _price = new BigNumber(data[0].minimumOffer).div(1e9).toString()
      setPrice(_price)
    }
  }

  useEffect(() => {
    fetchMarketplaceInfo()
  }, [id])

  const getBids = async () => {
    try {
      if (id && contractHash) {
        const _bid = await bidCallback()
        setBidHistory(_bid)
        setTotalBid(_bid?.length ? _bid?.length : 0)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getBids()

    const interval = setInterval(() => {
      getBids()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [id, contractHash, account])

  useEffect(() => {
    const _currentBid = bidHistory.filter(bid => bid.bidder === accountHash)
    if (_currentBid?.length > 0) {
      setBidding(true)
      const _currentBiddingPrice = new BigNumber(_currentBid[0].biddingPrice).div(1e9).toString()
      setCurrentBiddingPrice(_currentBiddingPrice)
    } else {
      setBidding(false)
    }
  }, [bidHistory, accountHash])

  // @ts-ignore
  useEffect(async () => {
    const nftMetadata = await nftMetadataCallback()
    setImgUrl(nftMetadata?.metadata?.asset)
  }, [id])

  const onBuyNFT = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Buy NFT')
      let gasFee = 35000000000
      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=true&txType=buy&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }

        const value = Number(price)
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(nft.nftContract, 'hex')))
        const nftMarketHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Market, 'hex')))

        const runtimeArgs = RuntimeArgs.fromMap({
          deposit_entry_point_name: CLValueBuilder.string('buy'),
          amount: CLValueBuilder.u512(value * 1e9),
          marketplace_hash: createRecipientAddress(nftMarketHash),
          nft_contract_hash: createRecipientAddress(nftContractHash),
          token_id: CLValueBuilder.string(nft.tokenId),
          buyer: createRecipientAddress(CLPublicKey.fromHex(account)),
        })

        const response = await axios.get('/payment_contract.wasm', {
          responseType: 'arraybuffer',
        })
        const instance = new Uint8Array(Buffer.from(response.data, 'binary'))

        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newModuleBytes(instance, runtimeArgs),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Buy ${price} CSPR #${nft.tokenId}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onCancelSell = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Sell')
      let gasFee = 9200000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=false&txType=revoke_sell&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }

        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Box, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_sell',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(id),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          // @ts-ignore
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined
          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            // @ts-ignore
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Cancel sell #${id}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const onCancelBid = async () => {
    try {
      setShowConfirm(true)
      setAttemptingTxn(true)
      setTitle('Cancel Bid')
      let gasFee = 10000000000

      if (account && currentNetwork) {
        const _gasFee = await axios.get(
          `${currentNetwork.urlApi}/getfee?isDoneSell=false&txType=revoke_bid&totalBid=${totalBid}`,
        )
        if (_gasFee.status === 200 && _gasFee.data.gasFee) {
          // eslint-disable-next-line prefer-destructuring
          gasFee = _gasFee.data.gasFee
        }

        // @ts-ignore
        const senderKey = CLPublicKey.fromHex(account)
        const deployParams = new DeployUtil.DeployParams(senderKey, currentNetwork?.key ?? 'casper-test', 1, 1800000)
        // @ts-ignore
        const contractHashAsByteArray = decodeBase16(currentNetwork.contract.Market)
        const nftContractHash = new CLByteArray(Uint8Array.from(Buffer.from(currentNetwork.contract.Box, 'hex')))
        const deploy = DeployUtil.makeDeploy(
          deployParams,
          DeployUtil.ExecutableDeployItem.newStoredContractByHash(
            contractHashAsByteArray,
            'revoke_bid',
            RuntimeArgs.fromMap({
              token_id: CLValueBuilder.string(id),
              nft_contract_hash: CLValueBuilder.key(nftContractHash),
            }),
          ),
          DeployUtil.standardPayment(gasFee),
        )

        if (deploy && provider) {
          const json = DeployUtil.deployToJson(deploy)
          // @ts-ignore
          const casperClient = new CasperClient(currentNetwork.rpcURL)

          let signature: any = undefined
          let deployObject: any = undefined

          if (connectorId === 'caspersigner' || connectorId === 'casperdash') {
            signature = await provider.sign(json, account, account)
            const _deploy = DeployUtil.deployFromJson(signature)
            deployObject = _deploy.val
          } else {
            signature = await provider.sign(JSON.stringify(json), account)
            // @ts-ignore
            deployObject = DeployUtil.setSignature(deploy, signature.signature, CLPublicKey.fromHex(account))
          }

          casperClient
            .putDeploy(deployObject)
            .then(async (hash: any) => {
              addTransaction(hash, {
                summary: `Cancel bid #${id}.`,
              })

              setTxHash(hash)
              setAttemptingTxn(false)
            })
            .catch((error: any) => {
              console.error(error)
              setShowConfirm(false)
              setAttemptingTxn(false)
              toast.error(error)
            })
        }
      }
    } catch (error) {
      console.error(error)
      setShowConfirm(false)
      setAttemptingTxn(false)
    }
  }

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [txHash])

  return (
    <Layout>
      <SectionWrapper>
        <Container>
          <ContentWrapper>
            <ItemImage src={LootBox} />
            <InfoWrapper className="col">
              <ItemName>#{id}</ItemName>
              <Description>
                CasperPunks Gen 1 are rare and unique Digital Collectibles with only 2597 currently available in the
                world. All unsold CasperPunk Gen 1’s during the whitelist and public sale are to be burned, leaving the
                remaining reserve only accessible to CasperPunk holders to attain through breeding and evolving in the
                future.
                <br />
                <br />
                Each CasperPunk is unique and was created with Casper’s environmentally friendly dynamic NFT smart
                contract standards. Holders get to experience expanding utility as part of the CasperPunks community.
                The Mystery Boxes will later be revealed as a unique CasperPunk, get yours now!
              </Description>
              <StyledTab defaultActiveKey="details">
                <Tab eventKey="details" title="Details">
                  <DetailsTab owner={tokenOwner} metaData={tokenMeta} symbol={tokenSymbol} />
                </Tab>
                <Tab eventKey="bids" title="Bids">
                  <BidsTab bidHistory={bidHistory} id={id} accountHash={accountHash} />
                </Tab>
                <Tab eventKey="history" title="History">
                  <HistoryTab nftContract={contractHash} tokenId={id} />
                </Tab>
              </StyledTab>
              <BidWrapper>
                {price && (
                  <div className="d-flex align-items-center">
                    <p>Price: </p>
                    <PriceTag price={price} symbol="CSPR" />
                  </div>
                )}
                {!account ? (
                  <ButtonWrapper>
                    <Button type="primary" handleClick={() => setShowConnectModal(true)}>
                      <CiWallet size={20} />
                      Connect Wallet
                    </Button>
                  </ButtonWrapper>
                ) : (
                  <ButtonWrapper>
                    {tokenOwner === accountHash && tokenOwner !== undefined && !isSelling && (
                      <Button type="primary" handleClick={() => setShowSellModal(true)} padding="8px 48px">
                        Sell now
                      </Button>
                    )}
                    {tokenOwner === accountHash && tokenOwner !== undefined && isSelling && (
                      <>
                        <Button
                          type="primary"
                          loading={attemptingTxn}
                          handleClick={() => setShowChangePriceModal(true)}
                          padding="9px 30px"
                        >
                          Change Price
                        </Button>
                        <Button
                          type="outline"
                          loading={attemptingTxn}
                          padding="9px 40px"
                          handleClick={() => onCancelSell()}
                        >
                          Cancel Sell
                        </Button>
                      </>
                    )}
                    {tokenOwner !== accountHash && nft?.isActive && (
                      <Button type="primary" loading={attemptingTxn} handleClick={onBuyNFT} padding="8px 48px">
                        {attemptingTxn ? `Buying...` : <>Buy Now</>}
                      </Button>
                    )}
                    {tokenOwner !== accountHash && tokenOwner !== undefined && (
                      <>
                        {isBidding ? (
                          <>
                            <Button
                              type="primary"
                              loading={attemptingTxn}
                              padding="9px 33px"
                              handleClick={() => setShowIncreaseBidModal(true)}
                            >
                              Increase Bid
                            </Button>
                            <Button
                              type="outline"
                              padding="8px 39px"
                              loading={attemptingTxn}
                              handleClick={() => onCancelBid()}
                            >
                              Cancel Bid
                            </Button>
                          </>
                        ) : (
                          <Button type="outline" handleClick={() => setShowBidModal(true)} padding="8px 71px">
                            Bid
                          </Button>
                        )}
                      </>
                    )}
                  </ButtonWrapper>
                )}
              </BidWrapper>
            </InfoWrapper>
          </ContentWrapper>

          <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
          <SellNFTModal
            nft={nftForSell}
            show={showSellModal}
            totalBid={totalBid}
            onHide={() => setShowSellModal(false)}
          />
          <BidNFTModal
            nft={nft}
            show={showBidModal}
            id={id}
            totalBid={totalBid}
            onHide={() => setShowBidModal(false)}
          />
          <IncreaseBidNFTModal
            currentBid={currentBiddingPrice}
            nft={nft}
            id={id}
            show={showIncreaseBidModal}
            onHide={() => setShowIncreaseBidModal(false)}
          />
          <ChangePriceNFTModal nft={nft} show={showChangePriceModal} onHide={() => setShowChangePriceModal(false)} />
        </Container>
      </SectionWrapper>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        title={title}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        pendingText=""
        onDismiss={handleDismissConfirmation}
        content={() => <></>}
      />
    </Layout>
  )
}

export default ItemDetails
