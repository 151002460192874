import { Offcanvas } from 'react-bootstrap'
import { StyledLink, MainMenu, MobileMenu, NavBarWrap } from './Styled'

interface INavigationProps {
  darkMode?: boolean
  isOpen?: boolean
  onHide?: any
  footer?: boolean
}

function Navigation(props: INavigationProps): JSX.Element {
  const { darkMode, isOpen, onHide, footer } = props

  return (
    <MainMenu>
      {footer ? (
        <NavBarWrap className="d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <StyledLink href="https://casperpunks.io/termofuse" darkMode={darkMode} target="_blank">
                Term Of Use
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/policy" darkMode={darkMode} target="_blank">
                Privacy Policy
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/faq" darkMode={darkMode} target="_blank">
                FAQ
              </StyledLink>
            </li>
          </ul>
        </NavBarWrap>
      ) : (
        <NavBarWrap className="d-none d-lg-flex flex-grow-1" footer={footer}>
          <ul className="main-menu list-unstyled">
            <li>
              <StyledLink href="https://casperpunks.io/#about" darkMode={darkMode} target="_blank">
                About
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#minting" darkMode={darkMode} target="_blank">
                Minting
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#roadmap" darkMode={darkMode} target="_blank">
                Roadmap
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/" darkMode={darkMode} market>
                Marketplace
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/faq" darkMode={darkMode} target="_blank">
                FAQ
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </StyledLink>
            </li>
          </ul>
        </NavBarWrap>
      )}
      <Offcanvas show={isOpen} onHide={onHide}>
        <Offcanvas.Header closeButton />
        <Offcanvas.Body>
          <MobileMenu>
            <li>
              <StyledLink href="https://casperpunks.io/#about" darkMode={darkMode} target="_blank">
                About
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#minting" darkMode={darkMode} target="_blank">
                Minting
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/#roadmap" darkMode={darkMode} target="_blank">
                Roadmap
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/" darkMode={darkMode} market>
                Marketplace
              </StyledLink>
            </li>
            <li>
              <StyledLink href="https://casperpunks.io/faq" darkMode={darkMode} target="_blank">
                FAQ
              </StyledLink>
            </li>
            <li>
              <StyledLink href="/connectwallet" darkMode={darkMode}>
                Create Wallet
              </StyledLink>
            </li>
          </MobileMenu>
        </Offcanvas.Body>
      </Offcanvas>
    </MainMenu>
  )
}

export default Navigation
