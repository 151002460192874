import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Web3ReactManager from 'components/Web3ReactManager'
import Header from 'components/Header'
import HeaderSecond from './components/HeaderSecond'
import Footer from './components/Footer'
import HomePage from './pages/HomePage'
import ItemDetails from './pages/ItemDetails'
// import CreateItem from 'pages/CreateItem'
import MyBoxes from 'pages/MyBoxes'
import Gen1Boxes from 'pages/Gen1Boxes'
import ConnectWallet from 'pages/ConnectWallet'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'assets/scss/style.scss'
import Popups from 'components/Popups'

// eslint-disable-next-line
function App() {
  return (
    <Web3ReactManager>
      <Router>
        <Header />
        <HeaderSecond />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact strict path="/details/:contractHash/:id">
            <ItemDetails />
          </Route>
          {/*<Route exact strict path="/create">*/}
          {/*  <CreateItem />*/}
          {/*</Route>*/}
          <Route exact strict path="/portfolio">
            <MyBoxes />
          </Route>
          <Route exact strict path="/gen1boxes">
            <Gen1Boxes />
          </Route>
          <Route exact strict path="/connectwallet">
            <ConnectWallet />
          </Route>
        </Switch>
        <Footer />
        <Popups />
      </Router>
    </Web3ReactManager>
  )
}

export default App
