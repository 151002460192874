import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components/macro'
import { useCurrentNetwork, useMarketplace, useMetadata } from 'hooks'
import { useAccount, useChainId } from 'state/wallet/hooks'
import SectionTitle from 'components/SectionTitle'
import AuctionBox from 'components/AuctionBox'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { Link } from 'react-router-dom'
import ConnectModal from 'components/ConnectButton/ConnectModal'

const SectionWrapper = styled.div`
  padding: 2.5rem 0;
  background: #161f2f;
`

const StyledSwiper = styled(Swiper)`
  padding-top: 3rem !important;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`

const StyledText = styled.h4`
  text-align: center;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 20px;
  color: #fff;
`

function Auctions(): JSX.Element {
  const account = useAccount()
  const chainId = useChainId()
  const currentNetwork = useCurrentNetwork()
  const marketplaceCallback = useMarketplace()

  const [loading, setLoading] = useState<boolean>(false)
  const [tokenMarket, setTokenMarket] = useState<any>([])
  const [showConnectModal, setShowConnectModal] = useState(false)

  const fetchNFTs = async () => {
    try {
      if (currentNetwork) {
        setLoading(true)
        // @ts-ignore
        const _nfts = await marketplaceCallback()

        if (_nfts) {
          setTokenMarket(_nfts)
        }
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchNFTs()
  }, [account, chainId, currentNetwork])

  return (
    <SectionWrapper>
      <Container>
        <SectionTitle align="center" color="light">
          Live Auctions
        </SectionTitle>
        <StyledSwiper
          slidesPerView={1}
          spaceBetween={10}
          loop={tokenMarket?.length > 3}
          autoplay={{
            delay: 5000,
          }}
          speed={1000}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader size="40px" stroke="#fff" />
            </div>
          ) : (
            <>
              {tokenMarket?.length > 0 ? (
                <>
                  {tokenMarket.map((nft, index) => (
                    <SwiperSlide key={index}>
                      <AuctionBox showBtn={true} nft={nft} connectWalletCallback={() => setShowConnectModal(true)} />
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <StyledText>No selling Digital Collectible found.</StyledText>
              )}
            </>
          )}
        </StyledSwiper>
        <ButtonWrapper>
          {tokenMarket?.length > 5 && (
            <Link to="/gen1boxes">
              <Button>See more</Button>
            </Link>
          )}
        </ButtonWrapper>
      </Container>
      <ConnectModal show={showConnectModal} onHide={() => setShowConnectModal(false)} />
    </SectionWrapper>
  )
}

export default Auctions
