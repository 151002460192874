import { useHistory } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow } from 'swiper'
import styled from 'styled-components/macro'
import Button from '../../components/Button'
import Hero1 from 'assets/images/SectionHero/gen0-1.gif'
import Hero2 from 'assets/images/SectionHero/gen0-2.gif'
import Hero3 from 'assets/images/SectionHero/gen0-3.gif'
import Hero4 from 'assets/images/SectionHero/gen0-4.gif'
import Hero5 from 'assets/images/SectionHero/gen0-5.gif'
import Hero6 from 'assets/images/SectionHero/gen0-6.gif'

const HeroWrapper = styled.div`
  background: transparent;
  position: relative;
  width: 100vw;
  padding: 5rem 0;
`

const HeroImage = styled.img`
  height: auto;
  object-fit: cover;
  width: 100%;
`

const TitleWrapper = styled.div`
  text-align: center;
  padding-top: 4rem;

  @media (min-width: 62rem) {
    margin: 0 10rem;
  }
`

const HeroTitle = styled.p`
  font-weight: 800;
  font-size: 50px;
  line-height: 55px;
  margin-bottom: 3rem;
  color: #ad203e;
  text-transform: capitalize;
`

const SubTitle = styled.p`
  font-weight: 300;
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 4rem;
  color: rgb(86, 86, 96);
`

const ButtonWrapper = styled.div`
  display: grid;
  text-align: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: 36rem) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

function SectionHero(): JSX.Element {
  const history = useHistory()
  return (
    <HeroWrapper>
      <Container>
        <Swiper
          effect={'coverflow'}
          loop={true}
          autoplay={{
            delay: 5000,
          }}
          speed={1000}
          spaceBetween={0}
          slidesPerView={3}
          centeredSlides={true}
          coverflowEffect={{
            rotate: -15,
            stretch: 0,
            depth: 300,
            modifier: 1,
            slideShadows: false,
          }}
          pagination={true}
          modules={[EffectCoverflow]}
        >
          <SwiperSlide>
            <HeroImage src={Hero1} />
          </SwiperSlide>
          <SwiperSlide>
            <HeroImage src={Hero2} />
          </SwiperSlide>
          <SwiperSlide>
            <HeroImage src={Hero3} />
          </SwiperSlide>
          <SwiperSlide>
            <HeroImage src={Hero4} />
          </SwiperSlide>
          <SwiperSlide>
            <HeroImage src={Hero5} />
          </SwiperSlide>
          <SwiperSlide>
            <HeroImage src={Hero6} />
          </SwiperSlide>
        </Swiper>
        <TitleWrapper>
          <HeroTitle>Punk up your life</HeroTitle>
          <SubTitle>
            Rare, Unique, Digital Collectibles
            <br />
            with Expanding Utility
          </SubTitle>
        </TitleWrapper>
        <ButtonWrapper>
          <Button type="primary" handleClick={() => history.push({ pathname: `/gen1boxes` })}>
            Explore now
          </Button>
          {/* <Link to="/create">
            <Button type="secondary">Create</Button>
          </Link> */}
        </ButtonWrapper>
      </Container>
    </HeroWrapper>
  )
}

export default SectionHero
